html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
html {
  overflow: hidden !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

mat-header-row.mat-header-row,
tr.mat-header-row {
  background: #e3ecfa;
  mat-header-cell,
  th.mat-header-cell {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
}

.mat-cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 14px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 170px;
}

.mat-paginator {
  display: flex !important;
  justify-content: center;
}

.header {
  $self: &;
  &__title {
    margin-top: 20px;
    margin-bottom: 29px;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #{ $self }__search {
      min-width: 420px;
      margin-right: 10px;
      width: 100%;
      margin-right: 10px;
    }
    #{ $self }__country {
      width: 30%;
      margin-right: 10px;
      &--full {
        width: 80%;
      }
    }
    #{ $self }__action {
      width: fit-content;
      height: 69px;
      button {
        height: 45px;
        width: 18.75vw;
        min-width: 240px;
      }
    }
  }
}

button.outline {
  border: 2px solid #27255f;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #27255f;
}

h1.dialog-title {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.buttons {
  display: flex;
  justify-content: space-between;
  button {
    width: 180px;
    height: 45px;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #27255f !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #e3ecfa !important;
}
